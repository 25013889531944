// アプリケーション名
// 開発初期PH(デジタルツール側の認証処理が未設定)ではsejdevlopを用いる
export const APPLICATION_NAME: string = "digital-tool-external";

// 環境名
export const ENV_LOCAL: string = "local";
export const ENV_DEV: string = "cloud-dev";
export const ENV_TEST: string = "cloud-test";
export const ENV_PROD: string = "cloud-prod";

// 初期表示するページ
export const INITIAL_PAGE: string = "/home";
export const LOGIN_PAGE: string = "/login";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import {
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { OAuthProvider, signInWithRedirect } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const microsoft365TenantId = process.env.REACT_APP_MICROSOFT365_TENANT_ID || "";
const provider = new OAuthProvider("microsoft.com");

// テナントを限定する
provider.setCustomParameters({ tenant: microsoft365TenantId });

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const customTokenSignIn = signInWithCustomToken;
export const emailAndPasswordSignIn = signInWithEmailAndPassword;
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

// Firebase Authentication API Token取得
export const getFirebaseAuthToken = async (isRefresh: boolean = true) => {
  return await auth.currentUser?.getIdToken(/* forceRefresh */ isRefresh);
};

export const microsoft365Login = () => {
  signInWithRedirect(auth, provider);
};

// Firebaseユーザー情報取得
export const getFirebaseUserInfo = (): any => {
  return auth.currentUser !== null ? auth.currentUser : {};
};

// Firebaseログイン判定
export const isFirebaseAuth = (): any => {
  return auth.currentUser !== null;
};

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  getFirebaseAuthToken,
} from "../src/firebase/firebase";
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { CircularProgress } from "@mui/material";
import { INITIAL_PAGE, LOGIN_PAGE, ENV_LOCAL, ENV_DEV } from "./const/constant";
import axios from "axios";

const App: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(true);

  // ログイン初期処理
  const initialLoginOperation = async () => {
    const host = process.env.REACT_APP_API_URL_MSAL!;
    let authorizationToken = await getFirebaseAuthToken();
    if (authorizationToken === undefined) authorizationToken = "./";
    // 次へボタンでログインを行う
    await axios
      .get(host + "msauth/check", {
        headers: { Authorization: "Bearer " + authorizationToken },
      })
      .then((result): any => {
        // ここのモジュールは開発環境でしっかり動かすためにはCORSを突破するために「npm run build-dev」でビルドし、
        // サーバーサイドの「src\main\resources\static」に配置して検証する必要があるので注意
        if (result.data.is_login) {
          // MS365ログイン済かつFirebase未ログインの場合でトークン経由でFirebaseログインを行う
          if ("firebase_token" in result.data) {
            signInWithCustomToken(auth, result.data.firebase_token)
              .then(() => {
                // Firebaseに認証されるとリダイレクトが起動して最初の流れに移る
                setLoginLoading(false);
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode + ":" + errorMessage);
              });
          }
        } else {
          // 未ログインの場合
          setLoginLoading(false);
          window.location.href = host + "auth/sign_in";
        }
      });
  };

  useEffect(() => {
    const unSub = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        // firebase認証済み
        setLoginLoading(false);
        navigate(INITIAL_PAGE);
      } else {
        // firebase認証未
        // (キャッシュ削除状態だと重いので注意)
        if (
          process.env.REACT_APP_MODE === ENV_LOCAL ||
          process.env.REACT_APP_MODE === ENV_DEV
        ) {
          // ローカルまたは開発環境ではログイン画面へ遷移する
          // navigate(LOGIN_PAGE);
        }
        // ローカル開発環境で実施するときはドメイン追加が必要なので注意
        // https://console.firebase.google.com/u/0/project/digital-tools-external-dev/authentication/settings
        initialLoginOperation();
      }
    });
    return () => {
      unSub();
    };
  }, []);

  return (
    <>
      {loginLoading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            size={"40vh"}
            style={{
              color: "#ccc",
              top: "30vh",
              position: "absolute",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;

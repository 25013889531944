import React, { useEffect, FC } from "react";
import Header from "../common/Header";
import { getRequest } from "../../apis/axiosAction";
import { Link } from "@mui/material";
/** @jsxImportSource @emotion/react */
import Typography from "@mui/material/Typography";

// import styled from "@emotion/styled";
import { Grid, Box, Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

// アプリケーション定数
const WINDOW_NAME = "お取引会社様向けデジタルツール";
const CHAT_BOT = "chat-bot";
const OPVIEW = "ofc-counselling";
const TENPO_CHECK = "tenpo-check";
const OFC_COUNSELLING = "acn-ofc-counselling";
const OFC_DASHBOARD = "ofc-dashboard";
const SHOP_ITEM_TAG = "shop-item-tag";
const CHAT_AI = "chatAi";

let appInfo: any = {};

const Home: React.FC<{}> = ({}) => {
  const [apps, setApps] = React.useState<string[]>([]);
  const [displayMode, setDisplayMode] = React.useState("initial");

  useEffect(() => {
    getUserInformation();
  }, []);

  // ボタン押下時の処理
  const moveAuthUrl = (appName: string, windowFlg: boolean) => {
    const authUrl: string = getAppInfo(appName, "auth-url");
    if (authUrl === "") {
      // 「auth-url」が空の場合認証トークンを取得しない
      const parametersString: string = "";
      const url = getAppInfo(appName, "site-url") + parametersString;
      if (windowFlg) {
        window.open(url, "_blank", "noreferrer");
      } else {
        window.location.href = url;
      }
      return;
    }
    getRequest(authUrl, {})
      .then((dataList) => {
        let token: string = "";
        const parametersString: string = "";
        if (authUrl.indexOf("v1/user-auth/usercheck") !== -1) {
          token = dataList[0].token;
        } else {
          token = dataList;
        }
        const tokenName = getAppInfo(appName, "token");
        const url = `${getAppInfo(
          appName,
          "site-url",
        )}?${tokenName}=${token}${parametersString}`;

        if (windowFlg) {
          window.open(url, "_blank", "noreferrer");
        } else {
          window.location.href = url;
        }
      })
      .catch((err) => {
        console.log("MoveAuthUrlError");
        console.log(err);
      });
  };

  const getUserInformation = () => {
    const url =
      process.env.REACT_APP_API_URL_J21SPB32! +
      "users/info/get-user-information";
    getRequest(url, {})
      .then((response: any) => {
        // 利用可能なアプリ
        let apps: string[] = [];
        // ユーザー情報取得でエラーの場合最初に戻す
        if (response.statusCode === "900") {
          const msg = "ユーザー情報取得エラー レスポンス無し(ステータス900)";
          console.log(msg);
          setDisplayMode(msg);
        }
        if (response.success === false) {
          const msg = "ユーザー情報取得エラー レスポンス無し(処理失敗)";
          console.log(msg);
          setDisplayMode(msg);
        }
        const userJson = JSON.parse(response);
        if (userJson && userJson["application_infomation"]) {
          for (const key in userJson["application_infomation"]) {
            appInfo[key] = userJson["application_infomation"][key];
          }
        } else {
          const msg =
            "userJsonおよびuserJson['application_infomation']が存在しない場合、エラー回避のため終了する";
          console.log(msg);
          setDisplayMode(msg);
          return;
        }
        // 下記は通常デジタルツール画面
        if (userJson["apps"] === undefined) {
          // 空のユーザーはチャットボットを閲覧可能
          apps = [];
        } else if (
          userJson["mail_address"] === undefined ||
          userJson["job_category_code_OA"] === undefined
        ) {
          // メールアドレスや職種コードがないユーザーは配列を付与(検証環境向け)
          apps = userJson["apps"];
        } else {
          apps = setApplication(
            userJson["apps"],
            userJson["mail_address"],
            userJson["job_category_code_OA"],
            userJson["department_code_OA"],
          );
        }
        console.log("利用可能なアプリ:", apps);
        setApps(apps);
        setDisplayMode("apps");
      })
      .catch((error: any) => {
        console.log("ユーザー情報取得エラー:", error);
      });
  };

  // ユーザー種別に応じて利用可能なアプリケーションを設定する(極力使わないようにすること)
  const setApplication = (
    apps: any,
    mail_address: string = "",
    job_category_code_OA: string = "",
    department_code_OA: string = "",
  ) => {
    // デジタルツール上のボタン非表示
    let exclusionApps: string[] = [];
    // iPhoneの場合
    // if (getDeviceType() === "iphone") {
    //   exclusionApps = [];
    // }
    apps = apps.filter((app: string) => !exclusionApps.includes(app));
    // 職種コード・メールアドレスが存在しない場合既存アプリで返す
    if (mail_address === undefined || job_category_code_OA === undefined) {
      return apps;
    }
    return apps;
  };

  // モード別の表示管理を行う
  const DisplayManagement: FC = () => {
    switch (displayMode) {
      case "initial":
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CircularProgress
              size={"40vh"}
              style={{
                color: "#ccc",
                top: "30vh",
                position: "absolute",
              }}
            />
          </div>
        );
      case "blank":
        return <></>;
      case "noapps":
        return (
          <div>
            <p>閲覧権限がありません</p>
          </div>
        );
      case "apps":
        return (
          <>
            <Header windowName={WINDOW_NAME} />
            <ButtonsManagement />
          </>
        );
      default:
        return (
          <div>
            <p>{displayMode}</p>
          </div>
        );
    }
  };

  // ボタン群の表示管理を行う
  const ButtonsManagement: FC = () => {
    const appsButtons = apps.map((app: string, index) => (
      <React.Fragment key={index}>
        <Grid sx={{ height: "15px" }} />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: "8vh" }}
        >
          <ButtonContent app={app} index={index} />
        </Grid>
      </React.Fragment>
    ));
    return (
      <Grid container style={{ marginTop: "8vh" }}>
        {appsButtons}
      </Grid>
    );
  };

  // ボタン別の表示管理を行う
  const ButtonContent: FC<{ app: string; index: number }> = ({
    app,
    index,
  }) => {
    const homeButtonStyle = {
      borderRadius: "10px",
      width: "470px",
      height: "54px",
      backgroundColor: "#00CC99",
      color: "white",
      "&:hover": {
        backgroundColor: "#00CC99",
      },
    };

    const homeMainMenu = {
      fontWeight: "bold",
      fontSize: "20px",
    };
    const homeButtonFontStyle = {
      color: "white",
    };

    const getAppNameIcon = (appName: string, typeName: string) => {
      const iconDir = `${window.location.origin}/image/icons/`;
      const applicationName: string = appInfo[appName].app_name;
      if (applicationName.match(/opview/i)) {
        // iフラグにより大文字小文字を無視したマッチングを行う
        return (
          <Link sx={homeButtonFontStyle}>
            <Box
              component="img"
              src={iconDir + "op_view_icon.png"}
              alt=""
              sx={{ width: "32px", height: "32px" }}
            />
            {applicationName}
          </Link>
        );
      }
      if (applicationName.match(/店舗確認・One Vision/)) {
        return (
          <Link sx={homeButtonFontStyle}>
            <Box
              component="img"
              src={iconDir + "op_view_icon.png"}
              alt=""
              sx={{ width: "32px", height: "32px" }}
            />
            {applicationName}
          </Link>
        );
      }
      return <Link sx={homeButtonFontStyle}>{applicationName}</Link>;
    };

    // ボタン押下時
    const handleClick = (app: string) => {
      console.log("アプリ遷移ボタン押下:" + app, "");
      moveAuthUrl(app, false);
    };

    if (!(app in appInfo)) {
      // ユーザーのappに含まれるものの「application_infomation」に含まれないものは表示しない
      return <></>;
    }

    return (
      <Grid
        container
        sx={homeButtonStyle}
        component={Button}
        onClick={() => handleClick(app)}
      >
        <Grid item xs={11}>
          <Typography sx={homeMainMenu}>
            {getAppNameIcon(app, "app-name")}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <LaunchIcon sx={{ color: "black" }} />
        </Grid>
      </Grid>
    );
  };
  return <DisplayManagement />;
};
export default Home;

// アプリケーションの情報を取得する
const getAppInfo = (appName: string, typeName: string): string => {
  const isUserToken = "is_usertoken";
  if (typeName === "auth-url") {
    return appInfo[appName].auth_url;
  } else if (typeName === "site-url") {
    return appInfo[appName].site_url;
  } else if (typeName === "app-name") {
    return appInfo[appName].app_name;
  } else if (typeName === "token" && appName.startsWith(OFC_COUNSELLING)) {
    return "usertoken";
  } else if (typeName === "token" && appName.startsWith(SHOP_ITEM_TAG)) {
    return "usertoken";
  } else if (typeName === "token" && appName.startsWith(CHAT_AI)) {
    return "usertoken";
  } else if (typeName === "token" && appInfo[appName][isUserToken]) {
    return "usertoken";
  } else if (typeName === "token") {
    return "token";
  }
  return "";
};
